<template>
    <div>

        <md-field>
            <label>Referencia de la parcela</label>
            <md-input :value="peticion.refName" disabled></md-input>
        </md-field>

        <md-field v-if="peticion.municipio">
            <label>Municipio</label>
            <md-input :value="peticion.municipio" disabled></md-input>
        </md-field>

        <md-field>
            <label>Acequia</label>
            <md-input :value="peticion.acequia" disabled></md-input>
        </md-field>

        <md-field>
            <label>Tipo de Cultivo</label>
            <md-input :value="peticion.tipoCultivo" disabled></md-input>
        </md-field>

        <md-field>
            <label>Superficie</label>
            <md-input :value="formatearNumero(peticion.superficie)" disabled></md-input>
            <span class="md-suffix">Ha</span>
        </md-field>

        <md-field>
            <label>Estado</label>
            <md-input :value="peticion.estado" disabled></md-input>
        </md-field>

        <md-field>
            <label>Fecha de alta</label>
            <md-input :value="formatearFecha(peticion.fechaAlta)" disabled></md-input>
        </md-field>
        
        <md-field v-if="peticion.fechaAtendida">
            <label>Fecha atendida</label>
            <md-input :value="formatearFecha(crearFechaPorPartes(peticion.fechaAtendida,peticion.horaAtender,peticion.minutoAtender))" disabled></md-input>
        </md-field>

        <md-field v-if="peticion.fechaFin">
            <label>Fecha de finalización</label>
            <md-input :value="formatearFecha(crearFechaPorPartes(peticion.fechaFin,peticion.horaFin,peticion.minutoFin))" disabled></md-input>
        </md-field>

        <md-field v-if="peticion.caudal">
            <label>Caudal</label>
            <md-input :value="formatearNumero(peticion.caudal)" disabled></md-input>
            <span class="md-suffix">L/s</span>
        </md-field>

        <md-field v-if="peticion.consumo">
            <label>Consumo</label>
            <md-input :value="formatearNumero(peticion.consumo,0)" disabled></md-input>
            <span class="md-suffix">m3/Ha</span>
        </md-field>

        <md-field v-if="peticion.consumoAcumulado">
            <label>Consumo acumulado parcela</label>
            <md-input :value="formatearNumero(peticion.consumoAcumulado,0)" disabled></md-input>
            <span class="md-suffix">m3/Ha</span>
        </md-field>

    </div>
</template>

<script>

export default {
    name: "Peticion",
    computed: {
        peticion(){
            let idPeticion = this.$route.params.id

            // Primero miro en als peticioens propias
            let peticiones = this.$store.state.peticiones
            for(let i=0; i<peticiones.length; i++){
                if ( peticiones[i].codPeticion === idPeticion ){
                    return peticiones[i];
                }
            }

            // Si no están ahí, puede que venga de las peticioens de la comunidad
            peticiones = this.$store.state.peticionesComunidad
            for(let i=0; i<peticiones.length; i++){
                if ( peticiones[i].codPeticion === idPeticion ){
                    // en este caso, ha venido de las peticiones d ela comunidad seguro, por lo que cambio el funcionamiento de "atras"
                    this.$store.commit('setBackPath','/peticiones/comunidad');
                    return peticiones[i];
                }
            }
            return {};
        },
    },
    created() {
        this.$store.commit('setBackPath','/peticiones');
    }
}
</script>

<style scoped>

</style>