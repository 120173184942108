<template>
    <crear-cultivo :id-parcela="idParcela" @finalizado="back"></crear-cultivo>
</template>

<script>
import CrearCultivo from "@/components/CrearCultivo";

export default {
    name: "NuevoCultivo",
    data() {
        return{
            idParcela: null
        }
    },
    components: {
        CrearCultivo,
    },
    methods: {
        back(){
            this.$router.push(this.$store.state.backPath);
        }
    },
    created() {
        this.idParcela = this.$route.params.id;
        this.$store.commit('setBackPath','/parcela/'+this.idParcela);
    }
}
</script>

<style scoped>

</style>