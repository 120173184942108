<template>
    <div>

        <div style="min-height: CALC(100vh - 260px);">
            <div class="md-layout md-gutter md-alignment-center">
                <div class="md-layout-item md-small-size-50">
                    <md-field>
                        <label>Parcela</label>
                        <md-select v-model="codParcela">
                            <md-option value="">Todas</md-option>
                            <md-option v-for="parcela in parcelas" :value="parcela.codParcela">{{ parcela.alias ? parcela.alias : parcela.refName }}</md-option>
                        </md-select>
                    </md-field>
                </div>
                <div class="md-layout-item md-small-size-50">
                    <md-field>
                        <label>Estado</label>
                        <md-select v-model="codEstado">
                            <md-option value="">Todos</md-option>
                            <md-option v-for="estado in estados" :value="estado.codEstado">{{ estado.estado }}</md-option>
                        </md-select>
                    </md-field>
                </div>
                <div class="md-layout-item md-small-size-50">
                    <md-field>
                        <label>Acequia</label>
                        <md-select v-model="codAcequia">
                            <md-option value="">Todas</md-option>
                            <md-option v-for="acequia in acequias" :value="acequia.codAcequia">{{ acequia.acequia }}</md-option>
                        </md-select>
                    </md-field>
                </div>
                <div class="md-layout-item md-small-size-50">
                    <md-field>
                        <label>Sector de riego</label>
                        <md-select v-model="codSectorRiego">
                            <md-option value="">Todos</md-option>
                            <md-option v-for="sectorRiego in sectoresRiego" :value="sectorRiego.codSectorRiego">{{ sectorRiego.sectorRiego }}</md-option>
                        </md-select>
                    </md-field>
                </div>
            </div>

            <md-table v-if="peticiones && peticiones.length > 0">
                <md-table-row>
                    <md-table-head>Fecha</md-table-head>
                    <md-table-head>Parcela</md-table-head>
                    <md-table-head>Cultivo</md-table-head>
                    <md-table-head>Estado</md-table-head>
                    <md-table-head></md-table-head>
                </md-table-row>
                <md-table-row v-for="peticion in peticiones" :key="peticiones.codPeticion" @click="detalles(peticion.codPeticion)">
                    <md-table-cell>{{ formatearFecha(peticion.fechaAlta,true) }}</md-table-cell>
                    <md-table-cell>{{ peticion.alias ? peticion.alias : peticion.refName }}</md-table-cell>
                    <md-table-cell>{{ peticion.tipoCultivo }}</md-table-cell>
                    <md-table-cell>
                        <md-badge :class="claseEstado(peticion.estado)" style="position: inherit !important;" :md-content="peticion.estado" />
                    </md-table-cell>
                    <md-table-cell md-numeric><md-icon>chevron_right</md-icon></md-table-cell>
                </md-table-row>
            </md-table>

            <md-empty-state v-else
                            md-icon="shower"
                            md-label="Sin peticiones"
                            :md-description="codAcequia || codSectorRiego ? 'No existen peticiones de riego con esos filtros' : 'No existen peticiones de riego asociadas a este usuario'">
            </md-empty-state>
        </div>

        <RouterLink to="peticiones/nueva">
            <md-button class="md-fab md-fab-bottom-right md-primary">
                <md-icon>add</md-icon>
            </md-button>
        </RouterLink>

    </div>
</template>

<script>

export default {
    name: "Peticiones",
    data() {
        return {
            codAcequia: "",
            codSectorRiego: "",
            codParcela: "",
            codEstado: "",
        }
    },
    computed: {
        peticiones(){
            let peticionesTotales = JSON.parse(JSON.stringify(this.$store.state.peticiones));
            if(this.codParcela){
                let peticionesFiltradas = [];
                for(let i=0; i<peticionesTotales.length; i++){
                    if(peticionesTotales[i].codParcela === this.codParcela){
                        peticionesFiltradas.push(peticionesTotales[i]);
                    }
                }
                peticionesTotales = peticionesFiltradas;
            }
            if(this.codEstado){
                let peticionesFiltradas = [];
                for(let i=0; i<peticionesTotales.length; i++){
                    if(peticionesTotales[i].codEstado === this.codEstado){
                        peticionesFiltradas.push(peticionesTotales[i]);
                    }
                }
                peticionesTotales = peticionesFiltradas;
            }
            if(this.codAcequia){
                let peticionesFiltradas = [];
                for(let i=0; i<peticionesTotales.length; i++){
                    if(peticionesTotales[i].codAcequia === this.codAcequia){
                        peticionesFiltradas.push(peticionesTotales[i]);
                    }
                }
                peticionesTotales = peticionesFiltradas;
            }
            if(this.codSectorRiego){
                let peticionesFiltradas = [];
                for(let i=0; i<peticionesTotales.length; i++){
                    if(peticionesTotales[i].codSectoresRiego.includes(this.codSectorRiego)){
                        peticionesFiltradas.push(peticionesTotales[i]);
                    }
                }
                peticionesTotales = peticionesFiltradas;
            }
            return peticionesTotales;
        },
        acequias(){
            let codAcequias = [];
            let acequias = [];
            for (let i=0; i<this.$store.state.peticiones.length; i++){
                if(this.$store.state.peticiones[i].codAcequia && !codAcequias.includes(this.$store.state.peticiones[i].codAcequia)){
                    acequias.push({
                        codAcequia: this.$store.state.peticiones[i].codAcequia,
                        acequia: this.$store.state.peticiones[i].acequia,
                    })
                    codAcequias.push(this.$store.state.peticiones[i].codAcequia);
                }
            }
            return acequias;
        },
        sectoresRiego(){
            return this.$store.state.sectoresRiego;
        },
        parcelas(){
            return this.$store.state.parcelas;
        },
        estados(){
            return this.$store.state.estados;
        }
    },
    methods: {
        detalles(codPeticion){
            this.$router.push("/peticion/"+codPeticion);
        },
        claseEstado(estado){
            let clase = "md-square ";
            if(estado === "Solicitada") clase += "bg-inactive";
            if(estado === "Anulada") clase += "bg-danger";
            if(estado === "Atendida") clase += "bg-info";
            if(estado === "Finalizada") clase += "bg-success";
            return clase;
        }
    },
    created() {
        this.$store.commit('setBackPath','/');
    }
}
</script>

<style lang="scss" scoped>

</style>