import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index';

import Home from '../views/Home.vue'
import Login from '../views/usuario/Login.vue'
import Logout from '../views/usuario/Logout.vue'
import Profile from '../views/usuario/Profile.vue'
import Parcelas from '../views/parcelas/Parcelas.vue'
import Parcela from '../views/parcelas/Parcela.vue'
import Peticiones from '../views/peticiones/Peticiones.vue'
import Peticion from '../views/peticiones/Peticion.vue'
import NuevaPeticion from '../views/peticiones/NuevaPeticion.vue'
import NuevoCultivo from '../views/parcelas/NuevoCultivo.vue'
import Comunidad from '../views/peticiones/Comunidad.vue'

Vue.use(VueRouter)

function ifAuthenticated(to, from, next) {
  if (store.getters.isLoggedIn) {
    next();
  }
  else{
    next("/login");
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter : ifAuthenticated
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    beforeEnter : ifAuthenticated
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { title: 'Perfil de usuario' },
    beforeEnter : ifAuthenticated
  },
  {
    path: '/parcelas',
    name: 'parcelas',
    component: Parcelas,
    meta: { title: 'Mis Parcelas' },
    beforeEnter : ifAuthenticated
  },
  {
    path: '/parcela/:id',
    name: 'parcela',
    component: Parcela,
    meta: { title: 'Datos de la parcela' },
    beforeEnter : ifAuthenticated
  },
  {
    path: '/peticiones',
    name: 'peticiones',
    component: Peticiones,
    meta: { title: 'Peticiones de riego' },
    beforeEnter : ifAuthenticated,
  },
  {
    path: '/peticiones/comunidad',
    name: 'Comunidad',
    component: Comunidad,
    meta: { title: 'Peticiones de riego' },
    beforeEnter : ifAuthenticated,
  },
  {
    path: '/peticion/:id',
    name: 'peticion',
    component: Peticion,
    meta: { title: 'Datos de la petición' },
    beforeEnter : ifAuthenticated
  },
  {
    path: '/peticiones/nueva',
    name: 'nuevaPeticion',
    component: NuevaPeticion,
    meta: { title: 'Nueva petición' },
    beforeEnter : ifAuthenticated
  },
  {
    path: '/parcela/:id/nuevo-cultivo',
    name: 'nuevoCultivo',
    component: NuevoCultivo,
    meta: { title: 'Nuevo cultivo' },
    beforeEnter : ifAuthenticated
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
