<template>
    <div>

        <md-table v-if="parcelas && parcelas.length > 0">
            <md-table-row>
                <md-table-head>Parcela</md-table-head>
                <md-table-head>Acequia</md-table-head>
                <md-table-head>Superficie</md-table-head>
                <md-table-head></md-table-head>
            </md-table-row>
            <md-table-row v-for="parcela in parcelas" :key="parcelas.nParcela" @click="detalles(parcela.codParcela)">
                <md-table-cell>{{ parcela.alias ? parcela.alias : parcela.refName }}</md-table-cell>
                <md-table-cell>{{ parcela.acequia }}</md-table-cell>
                <md-table-cell>{{ formatearNumero(parcela.superficie) }} Ha</md-table-cell>
                <md-table-cell md-numeric><md-icon>chevron_right</md-icon></md-table-cell>
            </md-table-row>
        </md-table>

        <md-empty-state v-else
            md-icon="list"
            md-label="Sin parcelas"
            md-description="No existen parcelas asociadas a este usuario">
        </md-empty-state>

    </div>
</template>

<script>

export default {
    name: "Parcelas",
    computed: {
        parcelas(){
            return this.$store.state.parcelas;
        }
    },
    methods: {
        detalles(codParcela){
            this.$router.push("/parcela/"+codParcela);

        }
    },
    created() {
        this.$store.commit('setBackPath','/');
    }
}
</script>

<style scoped>

</style>