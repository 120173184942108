<template>
    <div class="page-container">

        <md-app md-waterfall md-mode="fixed-last">

            <md-app-toolbar class="md-large md-dense md-primary" :style="toolbarStyle">

                <div class="md-toolbar-row">
                    <md-button v-if="backAvailable" class="md-icon-button" @click="back">
                        <md-icon>arrow_back</md-icon>
                    </md-button>
                    <span class="md-title">{{ title }}</span>
                </div>

                <div class="md-toolbar-row" v-if="inPeticionesRoute">
                    <md-tabs class="md-primary" md-alignment="fixed" md-sync-route>
                        <md-tab id="tab-peticiones" md-label="Propias" to="/peticiones" exact></md-tab>
                        <md-tab id="tab-comunidad" md-label="Comunidad" to="/peticiones/comunidad" exact></md-tab>
                    </md-tabs>
                </div>

            </md-app-toolbar>

            <md-app-content :style="contentStyle">
                <transition :name="transitionName">
                    <router-view></router-view>
                </transition>
            </md-app-content>

        </md-app>

        <md-bottom-bar v-if="isLoggedIn" md-sync-route class="bottom-navigate bg-alternative">
            <md-bottom-bar-item to="/" exact md-label="Inicio" md-icon="home" class="b-icon"></md-bottom-bar-item>
            <md-bottom-bar-item to="/parcelas" exact md-label="Parcelas" md-icon="map" class="b-icon"></md-bottom-bar-item>
            <md-bottom-bar-item to="/peticiones" exact md-label="Peticiones" md-icon="shower" class="b-icon"></md-bottom-bar-item>
            <md-bottom-bar-item to="/profile" exact md-label="Usuario" md-icon="person" class="b-icon"></md-bottom-bar-item>
        </md-bottom-bar>
        <md-toolbar v-else class="bg-alternative">
            <div class="md-layout md-gutter" style="width: 100%">
                <div class="md-layout-item md-small-size-70">
                    <p>Desarrollado por <a href="https://aljamir.com" target="_blank" style="color: var(--theme-secondary)">Aljamir Software S.L.</a></p>
                </div>
                <div class="md-layout-item md-small-size-30" style="text-align: right">
                    <p>Versión {{ appVersion }}</p>
                </div>
            </div>
        </md-toolbar>

    </div>
</template>

<script>

export default {
    name: 'App',
    data: () => ({
        menuVisible: false,
        title: "",
        paco: "dark",
        transitionName: "slide", // component-fade
    }),
    computed:{
        isLoggedIn(){
            return this.$store.getters.isLoggedIn;
        },
        inPeticionesRoute(){
            return this.$route.path === '/peticiones' || this.$route.path === '/peticiones/comunidad';
        },
        backAvailable(){
            return this.$route.path !== '/' && this.$route.path !== '/login';
        },
        toolbarStyle(){
            let style = "";
            if(!this.isLoggedIn){
                style += "visibility: hidden; ";
            }
            if(this.inPeticionesRoute){
                style += "min-height: 64px !important; ";
            }
            else{
                style += "min-height: 56px !important; ";
            }
            return style;
        },
        contentStyle(){
            let style = "";
            if(this.inPeticionesRoute){
                style += "padding-top: 64px !important; ";
            }
            return style;
        },
        appVersion() {
            return process.env.VUE_APP_VERSION
        }
    },
    watch: {
        isLoggedIn(valor) {
            if(valor) this.getData();
            else this.resetData();
        },
        $route: {
            immediate: true,
            handler(to, from) {
                this.title = to.meta.title || "CRR Marismas del Guadalquivir";
                let toDepth = to.path === "/" ? 1 :to.path.split("/").length;
                let fromDepth = 1;
                if(from) fromDepth = from.path === "/" ? 1 :from.path.split("/").length;
                if(toDepth < fromDepth){
                    this.transitionName = "slide-right";
                }
                else if(toDepth > fromDepth){
                    this.transitionName = "slide-left";
                }
                else{
                    this.transitionName = "fade";
                }
            }
        }
    },
    methods: {
        back(){
            if (this.backAvailable){
                this.$router.push(this.$store.state.backPath);
            }
        },
        getData(){
            this.$store.commit('setParcelas');
            this.$store.commit('setPeticiones');
            this.$store.commit('setCultivos');
            this.$store.commit('setSectoresRiego');
            this.$store.commit('setEstados');
            // this.$store.commit('setAcequias');
        },
        resetData(){
            this.$store.commit('reset');
        }
    }
}
</script>

<style lang="scss">

// Colores Personalizados
$azul-oscuro: #00347f;
$azul-medio: #b3d6ff;
$azul-claro: #e3f1ff;
$azul-menu: rgba(233, 247, 251, 0.48);
$rojo: #ff5252;
$verde: #13b99d;
$gris-claro: grey;
$gris-medio: #4d4d4d;
$gris-oscuro: #333;

@import "~vue-material/dist/theme/engine";

//@media (prefers-color-scheme: light) {
    @include md-register-theme("default", (
        primary: $azul-medio,
        accent: $azul-oscuro,
        theme: "light",
    ));
    @import "~vue-material/dist/theme/all";
//}

//@media (prefers-color-scheme: dark) {
//    @include md-register-theme("default", (
//        primary: md-get-palette-color(blue, A200),
//        accent: md-get-palette-color(red, A200),
//        theme: "dark",
//    ));
//    @import "~vue-material/dist/theme/all";
//}

// Variables del tema
:root {
    --theme-primary: #{$azul-medio};
    --theme-secondary: #{$azul-oscuro};
    --theme-alternative: #{$azul-claro};
    --theme-inactive: #{$gris-claro};
    --theme-info: #{$azul-medio};
    --theme-success: #{$verde};
    --theme-danger: #{$rojo};
    --theme-title: #{$gris-oscuro};
    --theme-text: #{$gris-medio};

    //--md-theme-default-background: #faffff;
    --md-theme-default-text-primary-on-accent: #{$azul-claro};
    //--md-theme-default-icon-on-background: #{$azul-medio};
    //--md-theme-default-primary-on-background: #{$azul-oscuro};
    --md-theme-default-icon-disabled: #{$azul-claro};
    --md-theme-default-text-primary-on-icon-disabled: #{$azul-oscuro};
}

// Clases propias
.bg-primary {
    background-color: var(--theme-primary) !important;
    color: var(--theme-text) !important;
}
.bg-secondary {
    background-color: var(--theme-secondary) !important;
    color: var(--theme-text) !important;
}
.bg-alternative {
    background-color: var(--theme-alternative) !important;
    color: var(--theme-text) !important;
}
.bg-inactive {
    background-color: var(--theme-inactive) !important;
    color: var(--theme-alternative) !important;
}
.bg-info {
    background-color: var(--theme-info) !important;
    color: var(--theme-text) !important;
}
.bg-success {
    background-color: var(--theme-success) !important;
    color: var(--theme-alternative) !important;
}
.bg-danger {
    background-color: var(--theme-danger) !important;
    color: var(--theme-alternative) !important;
}


// Ajustes del Material Desing
.md-app {
    height: CALC(100vh - 56px) !IMPORTANT;
    border-bottom: 1px solid rgba(#000, .12);
}
.page-container {
    height: 100%;
}
.b-icon {
    display: flex;
    place-content: center;
    margin: 0 auto!important;
}
.bloqueCentrado {
    justify-content: center;
    align-items: center;
    display: flex;
}
.md-table-cell-container {
    padding: 6px 10px 6px 10px !important;
}
.md-table-head-label {
    padding: 0 10px 0 10px !important;
}
.md-bottom-bar.md-theme-default.md-type-fixed .md-bottom-bar-item.md-active{
    color: #{$azul-oscuro}
}
.md-bottom-bar.md-theme-default.md-type-fixed .md-bottom-bar-item.md-active .md-icon{
    color: #{$azul-oscuro}
}
.md-field.md-theme-default.md-disabled label, .md-field.md-theme-default.md-disabled {
    color: var(--theme-title) !important;
}
.md-fab-bottom-right{
    position: sticky !important;
    float: right;
}
.md-tabs{
    width: 100%;
}

// Transiciones FADE
.fade-enter-active{
    transition: opacity .3s ease;
}
.fade-leave-active {
    opacity: 0;
}
.fade-enter, .fade-leave-to{
    position: absolute;
    opacity: 0;
}

// Transiciones SLIDE
.slide-left-leave-active,
.slide-left-enter-active,
.slide-right-leave-active,
.slide-right-enter-active {
    transition: all .3s ease;
}
.slide-left-enter {
    transform: translate(100%, 0);
    opacity: 0;
}
.slide-left-leave-to {
    position: absolute;
    transform: translate(-100%, 0);
    opacity: 0;
}
.slide-right-enter {
    transform: translate(-100%, 0);
    opacity: 0;
}
.slide-right-leave-to {
    position: absolute;
    transform: translate(100%, 0);
    opacity: 0;
}
</style>