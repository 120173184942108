<template>
    <div>
        <md-field>
            <label>Parcela</label>
            <md-select v-model="codParcelaSeleccionada" >
                <md-option v-for="parcela in parcelas" :value="parcela.codParcela">{{ parcela.refName }}{{ parcela.alias ? ': '+parcela.alias : '' }}</md-option>
            </md-select>
        </md-field>

        <md-field>
            <label>Cultivo</label>
            <md-select v-if="parcelaSeleccionada" v-model="codCultivoParcelaSeleccionado" >
                <md-option v-for="cultivo in parcelaSeleccionada.cultivos" :value="cultivo.codCultivoParcela">{{ cultivo.tipoCultivo }}</md-option>
                <md-option value="nuevo">+ Nuevo cultivo</md-option>
            </md-select>
        </md-field>

        <crear-cultivo v-if="codCultivoParcelaSeleccionado === 'nuevo'" :id-parcela="parcelaSeleccionada.codParcela" @finalizado="actualizarCultivos"></crear-cultivo>

        <md-button v-show="codCultivoParcelaSeleccionado !== 'nuevo'" class="md-raised md-primary" @click="crear" :disabled="!codCultivoParcelaSeleccionado">
            Crear petición
        </md-button>

        <md-dialog :md-active.sync="peticionOK" @md-closed="back" :md-fullscreen="false">
            <md-dialog-title>Petición creada</md-dialog-title>
            <md-dialog-content>Petición creada correctamente</md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-primary" @click="peticionOK = false">OK</md-button>
            </md-dialog-actions>
        </md-dialog>

        <md-dialog-alert
            :md-active.sync="peticionError"
            md-title="Error creando la petición"
            :md-content="peticionError"
        />

    </div>
</template>

<script>
import axios from "axios";
import CrearCultivo from "@/components/CrearCultivo";

export default {
    name: "NuevaPeticion",
    components: {
        CrearCultivo
    },
    data() {
        return {
            codParcelaSeleccionada: null,
            codCultivoParcelaSeleccionado: null,
            cultivos: [],
            peticionOK: null,
            peticionError: null,
        }
    },
    computed: {
        parcelas(){
            return this.$store.state.parcelas;
        },
        parcelaSeleccionada(){
            if(this.codParcelaSeleccionada){
                for (let i=0; i<this.parcelas.length; i++){
                    if(this.parcelas[i].codParcela === this.codParcelaSeleccionada){
                        return this.parcelas[i];
                    }
                }
            }
            else{
                this.codCultivoParcelaSeleccionado = null;
            }
            return null;
        }
    },
    methods: {
        crear(){
            const self = this;
            axios.post(process.env.VUE_APP_API_ENDPOINT+'peticiones/crear',
                {
                    codParcela: self.codParcelaSeleccionada,
                    codCultivoParcela: self.codCultivoParcelaSeleccionado,
                },
                {
                    headers: {Authorization: `Bearer ${self.$store.state.user.token}`}
                })
                .then( function (response) {
                    let result = response.data;
                    if(result === "OK"){
                        self.$store.commit('setPeticiones');
                        self.peticionOK = true;
                    }
                })
                .catch( function (error){
                    if(error.response){
                        self.peticionError = error.response.data;
                    }
                    else{
                        self.peticionError = "Error inesperado generando la petición";
                    }
                })
        },
        actualizarCultivos(codTipoCultivo){
            for (let i=0; i<this.parcelaSeleccionada.cultivos.length; i++){
                if(this.parcelaSeleccionada.cultivos[i].codTipoCultivo === codTipoCultivo){
                    this.codCultivoParcelaSeleccionado = this.parcelaSeleccionada.cultivos[i].codCultivoParcela;
                    break;
                }
            }
        },
        back(){
            this.$router.push(this.$store.state.backPath);
        }
    },
    created() {
        this.$store.commit('setBackPath','/peticiones');
    }
}
</script>

<style scoped>

</style>