<template>
    <div>

<!--        <md-field>-->
<!--            <label>Año</label>-->
<!--            <md-input :value="ano" type="number" disabled></md-input>-->
<!--        </md-field>-->

        <md-field>
            <label>Tipo de cultivo</label>
            <md-select v-model="codTipoCultivo" >
                <md-option v-for="cultivo in cultivos" :value="cultivo.codTipoCultivo" :disabled="cultivo.disabled">{{ cultivo.tipoCultivo }}</md-option>
            </md-select>
        </md-field>

        <md-field>
            <label>Superficie</label>
            <md-input v-model="superficie" type="number" min="0"></md-input>
            <span class="md-suffix">Ha</span>
        </md-field>

        <div v-if="parcela.superficie">
            <p>Superficie total parcela: {{ formatearNumero(parcela.superficie) }} Ha</p>
            <p>Superficie libre: {{ formatearNumero(superficieLibreParcela) }} Ha</p>
        </div>

        <md-button class="md-raised md-primary" @click="crear" :disabled="!disponibleEnviar">
            Crear cultivo
        </md-button>

        <md-dialog :md-active.sync="peticionOK" @md-closed="$emit('finalizado',codTipoCultivo)" :md-fullscreen="false">
            <md-dialog-title>Cultivo añadido</md-dialog-title>
            <md-dialog-content>Cultivo añadido correctamente</md-dialog-content>
            <md-dialog-actions>
                <md-button class="md-primary" @click="peticionOK = false">OK</md-button>
            </md-dialog-actions>
        </md-dialog>

        <md-dialog-alert
            :md-active.sync="peticionError"
            md-title="Error creando el cultivo"
            :md-content="peticionError"
        />
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "CrearCultivo",
    props:{
        idParcela: null
    },
    data() {
        return {
            ano: null,
            codTipoCultivo: null,
            superficie: null,
            peticionOK: null,
            peticionError: null
        }
    },
    computed: {
        cultivos(){
            return this.$store.state.cultivos;
        },
        parcela(){
            let parcela = null;
            let parcelas = JSON.parse(JSON.stringify(this.$store.state.parcelas));
            for (let i=0; i<parcelas.length; i++){
                if(parcelas[i].codParcela === this.idParcela){
                    parcela = parcelas[i];
                    break;
                }
            }
            return parcela;
        },
        disponibleEnviar(){
            return !!(this.codTipoCultivo && !isNaN(this.superficie) && this.superficie > 0 && this.superficieLibreParcela >= 0);
        },
        superficieLibreParcela(){
            let superficieLibre = parseFloat(this.parcela.superficie);
            for (let i=0; i<this.parcela.cultivos.length; i++){
                if(parseInt(this.parcela.cultivos[i].ano) === parseInt(this.ano)){
                    superficieLibre -= parseFloat(this.parcela.cultivos[i].superficie);
                }
            }
            if(this.superficie) superficieLibre -= parseFloat(this.superficie);
            return superficieLibre;
        }
    },
    methods: {
        crear(){
            const self = this;
            axios.post(process.env.VUE_APP_API_ENDPOINT+'parcelas/anadir-cultivo',
                {
                    codParcela: self.parcela.codParcela,
                    codTipoCultivo: self.codTipoCultivo,
                    superficie: self.superficie,
                },
                {
                    headers: {Authorization: `Bearer ${self.$store.state.user.token}`}
                })
                .then( function (response) {
                    let result = response.data;
                    if(result === "OK"){
                        self.$store.commit('setParcelas');
                        self.peticionOK = true;
                    }
                })
                .catch( function (error){
                    if(error.response){
                        self.peticionError = error.response.data;
                    }
                    else{
                        self.peticionError = "Error inesperado creando el cultivo";
                    }
                })
        },
        back(){
            this.$router.push(this.$store.state.backPath);
        }
    },
    created() {
        this.ano = new Date().getFullYear();
        this.$store.commit('setBackPath','/parcela/'+this.parcela.codParcela);
    }
}
</script>

<style scoped>

</style>