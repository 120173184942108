import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import axios from "axios";

// // estructura equivalente a clase, podría ser "user.js" // sería como sesión, cosas que se guardan internamente
export default new Vuex.Store({
    state: { // atributos public
        user: {
            propietario: "",
            codPropietario: "",
            idFiscal: "",
            direccion: "",
            municipio: "",
            token: ""
        },
        parcelas: [],
        peticiones: [],
        peticionesComunidad: [],
        acequias: [],
        sectoresRiego: [],
        cultivos: [],
        estados: [],
        backPath: "/"
    },
    getters: { // métodos que usan el state para calcular y devolver cosas
        isLoggedIn(state){
            return state.user.codPropietario;
        }
    },
    mutations: { // métodos que cambian el state
        login(state,user) {
            state.user = user
        },
        logout(state) {
            state.user = {};
        },
        setBackPath (state, path) {
            state.backPath = path;
        },
        setParcelas (state) {
            axios.get(process.env.VUE_APP_API_ENDPOINT+'parcelas',
                {
                    headers: {Authorization: `Bearer ${state.user.token}`}
                })
                .then( function (response) {
                    state.parcelas = response.data;
                })
                .catch( function (error){
                    console.log('e.pa.',error)
                })
        },
        setPeticiones (state) {
            axios.get(process.env.VUE_APP_API_ENDPOINT+'peticiones',
                {
                    headers: {Authorization: `Bearer ${state.user.token}`}
                })
                .then( function (response) {
                    state.peticiones = response.data;
                })
                .catch( function (error){
                    console.log('e.pe.',error)
                })
        },
        setPeticionesComunidad (state,callback) {
            axios.post(process.env.VUE_APP_API_ENDPOINT+'peticiones',
                {
                    todas: true
                },
                {
                    headers: {Authorization: `Bearer ${state.user.token}`}
                })
                .then( function (response) {
                    state.peticionesComunidad = response.data;
                    callback();
                })
                .catch( function (error){
                    console.log('e.peC.',error);
                    callback();
                })
        },
        setCultivos (state) {
            axios.get(process.env.VUE_APP_API_ENDPOINT+'cultivos',
                {
                    headers: {Authorization: `Bearer ${state.user.token}`}
                })
                .then( function (response) {
                    state.cultivos = response.data;
                })
                .catch( function (error){
                    console.log('e.cu.',error)
                })
        },
        setAcequias (state) {
            axios.get(process.env.VUE_APP_API_ENDPOINT+'acequias',
                {
                    headers: {Authorization: `Bearer ${state.user.token}`}
                })
                .then( function (response) {
                    state.acequias = response.data;
                })
                .catch( function (error){
                    console.log('e.ac.',error)
                })
        },
        setSectoresRiego (state) {
            axios.get(process.env.VUE_APP_API_ENDPOINT+'sectores-riego',
                {
                    headers: {Authorization: `Bearer ${state.user.token}`}
                })
                .then( function (response) {
                    state.sectoresRiego = response.data;
                })
                .catch( function (error){
                    console.log('e.cu.',error)
                })
        },
        setEstados (state) {
            axios.get(process.env.VUE_APP_API_ENDPOINT+'peticiones/estados',
                {
                    headers: {Authorization: `Bearer ${state.user.token}`}
                })
                .then( function (response) {
                    state.estados = response.data;
                })
                .catch( function (error){
                    console.log('e.es.',error)
                })
        },
        reset(state){
            state.parcelas = [];
            state.peticiones = [];
            state.peticionesComunidad = [];
            state.cultivos = [];
            state.acequias = [];
            state.sectoresRiego = [];
            state.estados = [];
        },
    }
})

