<template>
    <div>

        <div style="min-height: CALC(100vh - 230px);">
            <md-field>
                <label>Alias</label>
                <md-input v-model="alias"></md-input>
                <md-button class="md-icon-button md-dense" @click="cambiarAlias()" :disabled="!disponibleCambiarAlias">
                    <md-icon :style="disponibleCambiarAlias ? 'color: var(--theme-success)' : ''" >save</md-icon>
                </md-button>
            </md-field>

            <md-field>
                <label>Referencia</label>
                <md-input :value="parcela.refName" disabled></md-input>
            </md-field>

            <md-field v-if="parcela.municipio">
                <label>Municipio</label>
                <md-input :value="parcela.municipio" disabled></md-input>
            </md-field>

            <md-field>
                <label>Acequia</label>
                <md-input :value="parcela.acequia" disabled></md-input>
            </md-field>

            <md-field v-if="parcela.refCatastral">
                <label>Referencia catastral</label>
                <md-input :value="parcela.refCatastral" disabled></md-input>
            </md-field>

            <md-field v-if="parcela.sectoresRiego.length > 0">
                <label>Sectores de riego</label>
                <md-input :value="parcela.sectoresRiego.join(', ')" disabled></md-input>
            </md-field>

            <md-field v-if="parcela.telefono">
                <label>Teléfono</label>
                <md-input :value="parcela.telefono" disabled></md-input>
            </md-field>

            <md-field>
                <label>Superficie</label>
                <md-input :value="formatearNumero(parcela.superficie)" disabled></md-input>
                <span class="md-suffix">Ha</span>
            </md-field>

            <md-table v-if="parcela.cultivos && parcela.cultivos.length > 0">
                <md-table-toolbar>
                    <h3 class="md-title">Cultivos</h3>
                </md-table-toolbar>
                <md-table-row>
                    <md-table-head>Cultivo</md-table-head>
                    <md-table-head>Superficie</md-table-head>
                    <md-table-head>Fecha alta</md-table-head>
                    <md-table-head></md-table-head>
                </md-table-row>
                <md-table-row v-for="cultivo in parcela.cultivos" :key="cultivo.codCultivoParcela">
                    <md-table-cell>{{ cultivo.tipoCultivo }}</md-table-cell>
                    <md-table-cell>{{ formatearNumero(cultivo.superficie) }} Ha</md-table-cell>
                    <md-table-cell>{{ formatearFecha(cultivo.fechaALta) }}</md-table-cell>
                    <md-table-cell md-numeric>
                        <md-button class="md-icon-button" @click="cultivoAEliminar = cultivo; confirmarEliminarCultivo = true">
                            <md-icon>remove</md-icon>
                        </md-button>
                    </md-table-cell>
                </md-table-row>
            </md-table>

            <md-empty-state v-else
                            md-icon="grass"
                            md-label="Sin cultivos"
                            md-description="No existen cultivos asociados a esta parcela">
            </md-empty-state>

            <md-dialog-alert
                :md-active.sync="peticionCambiarAliasOK"
                md-title="Alias modificado"
                md-content="Alias modificado correctamente"
            />

            <md-dialog-alert
                :md-active.sync="peticionCambiarAliasError"
                md-title="Error modificando alias"
                :md-content="peticionCambiarAliasError"
            />

            <md-dialog-confirm
                :md-active.sync="confirmarEliminarCultivo"
                md-title="Eliminar cultivo"
                :md-content="textoConfirmacionElimar"
                md-confirm-text="Eliminar"
                md-cancel-text="Cancelar"
                @md-confirm="eliminarCultivo()" />

            <md-dialog-alert
                :md-active.sync="peticionEliminarCultivoOK"
                md-title="Cultivo eliminado"
                md-content="Cultivo eliminado correctamente"
            />

            <md-dialog-alert
                :md-active.sync="peticionEliminarCultivoError"
                md-title="Error eliminando el cultivo"
                :md-content="peticionEliminarCultivoError"
            />

        </div>

        <RouterLink :to="'/parcela/'+parcela.codParcela+'/nuevo-cultivo'">
            <md-button class="md-fab md-fab-bottom-right md-primary">
                <md-icon>add</md-icon>
            </md-button>
        </RouterLink>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Parcela",
    data() {
        return {
            alias: "",
            cultivoAEliminar: false,
            peticionEliminarCultivoOK: null,
            peticionEliminarCultivoError: null,
            confirmarEliminarCultivo: null,
            peticionCambiarAliasOK: null,
            peticionCambiarAliasError: null,
        };
    },
    computed: {
        parcela(){
            let idParcela = this.$route.params.id
            let parcelas = this.$store.state.parcelas
            for(let i=0; i<parcelas.length; i++){
                if ( parcelas[i].codParcela === idParcela ){
                    this.alias = parcelas[i].alias;
                    return parcelas[i];
                }
            }
            return {};
        },
        textoConfirmacionElimar(){
            if(this.cultivoAEliminar) return 'Está seguro de que desea eliminar el cultivo ' + this.cultivoAEliminar.tipoCultivo;
            else return "";
        },
        disponibleCambiarAlias(){
            return this.alias !== this.parcela.alias;
        }
    },
    methods: {
        eliminarCultivo(){
            const self = this;
            axios.post(process.env.VUE_APP_API_ENDPOINT+'parcelas/eliminar-cultivo',
                {
                    codCultivoParcela: self.cultivoAEliminar.codCultivoParcela,
                },
                {
                    headers: {Authorization: `Bearer ${self.$store.state.user.token}`}
                })
                .then( function (response) {
                    let result = response.data;
                    if(result === "OK"){
                        self.$store.commit('setParcelas');
                        self.peticionEliminarCultivoOK = true;
                    }
                })
                .catch( function (error){
                    if(error.response){
                        self.peticionEliminarCultivoError = error.response.data;
                    }
                    else{
                        self.peticionEliminarCultivoError = "Error inesperado eliminando el cultivo";
                    }
                })
        },
        cambiarAlias(){
            const self = this;
            if(self.disponibleCambiarAlias){
                axios.post(process.env.VUE_APP_API_ENDPOINT+'parcelas/cambiar-alias',
                    {
                        codParcela: self.parcela.codParcela,
                        alias: self.alias,
                    },
                    {
                        headers: {Authorization: `Bearer ${self.$store.state.user.token}`}
                    })
                    .then( function (response) {
                        let result = response.data;
                        if(result === "OK"){
                            self.$store.commit('setParcelas');
                            self.peticionCambiarAliasOK = true;
                        }
                    })
                    .catch( function (error){
                        if(error.response){
                            self.peticionCambiarAliasError = error.response.data;
                        }
                        else{
                            self.peticionCambiarAliasError = "Error inesperado cambiando el alias";
                        }
                    })
            }
        }
    },
    created() {
        this.$store.commit('setBackPath','/parcelas');
        this.alias = this.parcela.alias;
    }
}
</script>

<style scoped>

</style>