<template>
    <div class="home-container">
        <div class="md-layout sm-gutter md-alignment-center-center">

            <div class="md-layout-item md-size-40">
                <RouterLink to="/parcelas" style="text-align: center">
                    <md-avatar class="md-avatar-icon md-xlarge">
                        <md-icon class="md-size-3x">map</md-icon>
                    </md-avatar>
                    <span class="md-title home-avatar-text">PARCELAS</span>
                </RouterLink>
            </div>
            <div class="md-layout-item md-size-40">
                <RouterLink to="/peticiones" style="text-align: center">
                    <md-avatar class="md-avatar-icon md-xlarge">
                        <md-icon class="md-size-3x">shower</md-icon>
                    </md-avatar>
                    <span class="md-title home-avatar-text">PETICIONES</span>
                </RouterLink>
            </div>
            <div class="md-layout-item md-size-40">
                <RouterLink to="/profile" style="text-align: center">
                    <md-avatar class="md-avatar-icon md-xlarge">
                        <md-icon class="md-size-3x">person</md-icon>
                    </md-avatar>
                    <span class="md-title home-avatar-text">USUARIO</span>
                </RouterLink>
            </div>
            <div class="md-layout-item md-size-40">
                <RouterLink to="/logout" style="text-align: center">
                    <md-avatar class="md-avatar-icon md-xlarge">
                        <md-icon class="md-size-3x">logout</md-icon>
                    </md-avatar>
                    <span class="md-title home-avatar-text">SALIR</span>
                </RouterLink>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Home"
}
</script>

<style scoped>

template {
    height: 100vh;
}

.home-container {
    height: 70vh;
    display: flex;
    place-content: center;
}

.home-avatar-text {
    display: inherit;
    margin-top: 10px;
    color: var(--theme-secondary);
    text-decoration: none !important;
    text-align: center;
}

.md-layout-item {
    margin:1rem;
    display: flex;
    place-content: center;
}

.md-xlarge {
    min-width: 100px;
    min-height: 100px;
    border-radius: 100px;
    font-size: 100px;
}
</style>
