<template>
    <div>
        <div style="min-height: CALC(100vh - 300px);">
            <md-field>
                <label>Propietario</label>
                <md-input v-model="user.propietario" disabled></md-input>
            </md-field>
            <md-field>
                <label>ID Fiscal</label>
                <md-input v-model="user.idFiscal" disabled></md-input>
            </md-field>
            <md-field v-if="user.email">
                <label>Email</label>
                <md-input v-model="user.email" disabled></md-input>
            </md-field>
            <md-field v-if="user.direccion">
                <label>Dirección</label>
                <md-input v-model="user.direccion" disabled></md-input>
            </md-field>
            <md-field v-if="user.municipio">
                <label>Municipio</label>
                <md-input v-model="user.municipio" disabled></md-input>
            </md-field>
        </div>

        <md-toolbar class="bg-alternative">
            <div class="md-layout md-gutter" style="width: 100%">
                <div class="md-layout-item md-small-size-100">
                    <p>Desarrollado por <a href="https://aljamir.com" target="_blank" style="color: var(--theme-secondary)">Aljamir Software S.L.</a></p>
                    <p>Contacto: <a href="mailto:info@aljamir.com" target="_blank" style="color: var(--theme-secondary)">info@aljamir.com</a></p>
                    <p>Versión {{ appVersion }}</p>
                </div>
            </div>
        </md-toolbar>
    </div>
</template>

<script>
export default {
    name: "Profile",
    computed: {
        user(){
            return this.$store.state.user;
        },
        appVersion() {
            return process.env.VUE_APP_VERSION
        }
    },
    created() {
        this.$store.commit('setBackPath','/');
    }
}
</script>

<style scoped>

</style>