<template>
    <div class="login-container">
        <div class="bloqueCentrado" >
            <img alt="Vue logo" class="logo" src="../../assets/logo.png" />
        </div>

        <md-field md-clearable>
            <label>ID Fiscal</label>
            <md-input v-model="username"></md-input>
        </md-field>

        <md-field>
            <label>Contraseña</label>
            <md-input type="password" v-model="password"></md-input>
        </md-field>

        <div class="bloqueCentrado">
            <md-button class="md-raised md-primary" @click="login(username,password)">Iniciar sesión</md-button>
        </div>

        <div v-if="loginBiometriaDisponible" class="bloqueCentrado" style="margin-top: 10px">
            <md-button class=" md-accent" @click="loginBiometria" ><md-icon>fingerprint</md-icon> Iniciar sesión con biometría</md-button>
        </div>

        <md-dialog-alert
            :md-active.sync="mensajeErrorCredenciales"
            md-content="Usuario o contraseña incorrectos"
            md-confirm-text="OK"
        />

        <md-dialog-alert
            :md-active.sync="mensajeBiometrinaNodisponible"
            md-content="Biometria no disponible"
            md-confirm-text="OK"
        />

        <md-dialog-alert
            :md-active.sync="mensajeHuellaSinCargar"
            md-content="Antes de usar la biometría, debe haber iniciado sesión mediante usuario y contraseña al menos una vez"
            md-confirm-text="OK"
        />
    </div>
</template>

<script>

import axios from 'axios'

export default {
    name: "LoginView",
    data() {
        return {
            username: "",
            password: "",
            mensajeErrorCredenciales: false,
            mensajeBiometrinaNodisponible: false,
            mensajeHuellaSinCargar: false,
        }
    },
    computed: {
        loginBiometriaDisponible(){
            return (typeof window.cordova !== "undefined");
        }
    },
    methods: {
        login(username,password,biometria=false){
            const self = this;
            if(!username) username = self.username;
            if(!password) password = self.password;
            if(username && password){
                axios.post(process.env.VUE_APP_API_ENDPOINT+'login',
                    {
                        "username": username,
                        "password": password
                    },
                    {
                        headers: {'Content-Type': 'application/json'},
                    })
                    .then( function (response) {
                        if ( response.status === 200 ){
                            let user = response.data;
                            if(!biometria && self.loginBiometriaDisponible){
                                self.guardaCredencialesBiometricas(username,password);
                            }
                            self.$store.commit('login', user);
                            self.$router.push('/');
                        }
                    })
                    .catch( function (error){
                        self.mensajeErrorCredenciales = true;
                    })
            }
            else{
                self.mensajeErrorCredenciales = true;
            }
        },
        loginBiometria(){
            const self = this;
            window.Fingerprint.isAvailable(() => {
                window.Fingerprint.loadBiometricSecret({
                    title : "Acceso biométrico",
                    clientId : 'MarismasGuadalquivir102',
                    description : 'Cargando usuario...'
                }, (success) => {
                    let credenciales = success.split('|aljamir|');
                    self.login(credenciales[0],credenciales[1],true);
                }, (error) => {
                    self.mensajeHuellaSinCargar = true;
                });
            }, () => {
                self.mensajeBiometrinaNodisponible = true;
            });

        },
        guardaCredencialesBiometricas(username,password){
            window.Fingerprint.isAvailable(() => {
                let credenciales = username+"|aljamir|"+password;
                window.Fingerprint.registerBiometricSecret({
                    title : "Acceso biométrico",
                    clientId : 'MarismasGuadalquivir102',
                    secret : credenciales,
                    description : "Activando acceso con huella dactilar...",
                    disableBackup: true,
                    confirmationRequired: false,
                    cancelButtonTitle: "Cancelar"
                }, (success) =>{
                    console.log(success);
                }, (error) =>{
                    console.log(error)
                });
            });

        }
    },
    created() {
        if(this.$store.getters.isLoggedIn){
            this.$router.push('/');
        }
    }
}
</script>

<style scoped>

.logo {
    margin-top: 5px;
    margin-bottom: 30px;
    max-width: 200px;
    width: 80%;
}

.login-container {
    height: 65vh;
    width: 80vw;
    margin: 3vh 5vw;
}
</style>