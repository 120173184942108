<template>
    <div>
        <cargando v-if="cargando"></cargando>

        <div v-else class="md-layout md-gutter md-alignment-center">

            <div class="md-layout-item md-small-size-60">
                <md-field>
                    <label>Estado</label>
                    <md-select v-model="codEstado">
                        <md-option value="">Todos</md-option>
                        <md-option v-for="estado in estados" :value="estado.codEstado">{{ estado.estado }}</md-option>
                    </md-select>
                </md-field>
            </div>

            <div class="md-layout-item md-small-size-40">
                <md-button @click="recargarPeticionesComunidad()" class="md-raised md-primary" style="width: 100%">
                    <md-icon>cached</md-icon> Recargar
                </md-button>
            </div>

            <div class="md-layout-item md-small-size-100">
                <md-table v-if="peticionesFiltradas && peticionesFiltradas.length > 0">
                    <md-table-row>
                        <md-table-head>Fecha</md-table-head>
                        <md-table-head>Parcela</md-table-head>
                        <md-table-head>Cultivo</md-table-head>
                        <md-table-head>Estado</md-table-head>
                        <md-table-head></md-table-head>
                    </md-table-row>
                    <md-table-row v-for="peticion in peticionesFiltradas" :key="peticiones.codPeticion" @click="detalles(peticion.codPeticion)" :style="esPeticionPropia(peticion) ? 'background: var(--theme-alternative)' : ''">
                        <md-table-cell>{{ formatearFecha(peticion.fechaAlta, true) }}</md-table-cell>
                        <md-table-cell>{{ peticion.refName }}</md-table-cell>
                        <md-table-cell>{{ peticion.tipoCultivo }}</md-table-cell>
                        <md-table-cell>
                            <md-badge :class="claseEstado(peticion.estado)" style="position: inherit !important;" :md-content="peticion.estado" />
                        </md-table-cell>
                        <md-table-cell md-numeric><md-icon>chevron_right</md-icon></md-table-cell>
                    </md-table-row>
                </md-table>
                <md-empty-state v-else
                                md-icon="shower"
                                md-label="Sin peticiones"
                                md-description="No existen peticiones de riego con esos filtros">
                </md-empty-state>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";
import Cargando from "@/components/Cargando";

export default {
    name: "Comunidad",
    components: {
        Cargando,
    },
    data() {
        return {
            cargando: true,
            codEstado: "0",
        }
    },
    computed: {
        peticiones(){
            return this.$store.state.peticionesComunidad;
        },
        peticionesFiltradas(){
            let peticionesTotales = JSON.parse(JSON.stringify(this.peticiones));
            if(this.codEstado){
                let peticionesFiltradas = [];
                for(let i=0; i<peticionesTotales.length; i++){
                    if(peticionesTotales[i].codEstado === this.codEstado){
                        peticionesFiltradas.push(peticionesTotales[i]);
                    }
                }
                peticionesTotales = peticionesFiltradas;
            }
            return peticionesTotales;
        },
        peticionesUsuario(){
            return this.$store.state.peticiones;
        },
        estados(){
            return this.$store.state.estados;
        }
    },
    methods: {
        detalles(codPeticion){
            this.$router.push("/peticion/"+codPeticion);
        },
        claseEstado(estado){
            let clase = "md-square ";
            if(estado === "Solicitada") clase += "bg-inactive";
            if(estado === "Anulada") clase += "bg-danger";
            if(estado === "Atendida") clase += "bg-info";
            if(estado === "Finalizada") clase += "bg-success";
            return clase;
        },
        esPeticionPropia(peticion){
            for(let i=0; i<this.peticionesUsuario.length; i++){
                if(this.peticionesUsuario[i].codPeticion === peticion.codPeticion) return true;
            }
            return false;
        },
        recargarPeticionesComunidad(){
            const self = this;
            self.cargando = true;
            self.$store.commit('setPeticionesComunidad', function (){
                self.cargando = false;
            });
        }
    },
    created() {
        const self = this;
        self.$store.commit('setBackPath','/peticiones');
        if(self.$store.state.peticionesComunidad.length > 0){
            self.cargando = false;
        }
        else{
            self.recargarPeticionesComunidad();
        }
    }
}
</script>

<style scoped>

</style>