<template>
    <div class="logout-container">
        <div class="bloqueCentrado">
            <p>¿Desea cerrar sesión?</p>
        </div>
        <div class="bloqueCentrado">
            <md-button class="md-raised bg-danger" @click="logout">Cerrar Sesión</md-button>
            <md-button class="md-raised bg-primary" @click="back">Cancelar</md-button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "LogoutView",
    methods: {
        logout(){
            const self = this;
            axios.get("https://marismasguadalquivir.es/crr/api/logout",
                {
                    headers: {'Content-Type': 'application/json'},
                })
                .then( function (response) {
                    if ( response.status === 200 ){
                        self.$store.commit('reset');
                        self.$store.commit('logout');
                        self.$router.push('login');
                    }
                })
                .catch( function (error){
                    console.log(error)
                })
        },
        back(){
            this.$router.push(this.$store.state.backPath);
        }
    },
    created() {
        this.$store.commit('setBackPath','/');
    }
}
</script>

<style scoped>
.logout-container {
    height: 30vh;
    margin-top: 25vh;
    margin-bottom: 25vh;
}
</style>