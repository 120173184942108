import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Vue Material
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
Vue.use(VueMaterial)

// Constantes
Vue.config.productionTip = false;

// Helpers
Vue.mixin({
    methods: {
        formatearNumero(numero,decimalesMaximos=2,decimalesMinimos=0){
            let formatter =  new Intl.NumberFormat('es-ES', {
                minimumFractionDigits: decimalesMinimos,
                maximumFractionDigits: decimalesMaximos,
            });
            return formatter.format(numero);
        },
        formatearFecha(fecha, formatoCorto=false){
            let date = new Date(fecha);
            if(date instanceof Date && !isNaN(date)){
                let fechaFormateada = "";
                if(formatoCorto){
                    fechaFormateada = date.toLocaleString("es-ES", {
                        day: '2-digit',
                        month: '2-digit',
                    });
                }
                else{
                    fechaFormateada = date.toLocaleString("es-ES", {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit'
                    });
                }

                return fechaFormateada.replace(",", "")
            }
            else return "";
        },
        crearFechaPorPartes(fecha, hora, minutos, segundos){
            let result = fecha+"T";
            if(hora) result += this.zfill(hora, 2);
            else result += "00";
            result += ":"
            if(minutos) result += this.zfill(minutos, 2);
            else result += "00";
            result += ":"
            if(segundos) result += this.zfill(segundos, 2);
            else result += "00";
            result += ".000";
            return result;
        },
        zfill(number, width) {
            let numberOutput = Math.abs(number); /* Valor absoluto del número */
            let length = number.toString().length; /* Largo del número */
            let zero = "0"; /* String de cero */

            if (width <= length) {
                if (number < 0) {
                    return ("-" + numberOutput.toString());
                } else {
                    return numberOutput.toString();
                }
            } else {
                if (number < 0) {
                    return ("-" + (zero.repeat(width - length)) + numberOutput.toString());
                } else {
                    return ((zero.repeat(width - length)) + numberOutput.toString());
                }
            }
        }
    }
})

const init = () => {
    new Vue({
        router,
        store,
        render: function (h) { return h(App) }
    }).$mount('#app')
};

// Wait for the deviceready event to start the render
document.addEventListener("deviceready", () => {
    // eslint-disable-next-line
    console.log("Ready, Render the App");
    init();
});

// If we are not in Cordova, manually trigger the deviceready event
const isCordovaApp = (typeof window.cordova !== "undefined");
if (!isCordovaApp){
    document.dispatchEvent(new CustomEvent("deviceready", {}));
}